.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-div {
  width: 100%;
  height: 150px;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
}

.App-body {
  background: url(../public/图片3.jpg);
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 800px;
  z-index: -10;
  zoom: 1;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  height: 70px;
}

.App-hearder-logo {
  width: 70px;
  height: 70px;
}

.App-link {
  text-decoration: none;
  color: white;
}

.App-hearder-text {
  margin-left: 15px;
}

.App-text-div {

  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header-text-div {

  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  font-weight: bold;
  font-size: 22px;
  padding: 0 50px;
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}